<template>
    <div>
        <el-card class="box-card">
            <div slot="header" >
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>产品管理</el-breadcrumb-item>
                    <el-breadcrumb-item>产品管理</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div>
                <div style="margin-left: 10px">
                    <el-form :model="TmForm"  ref="queryCommodityForm" class="demo-ruleForm">
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="产品名称:" >
                                    <el-input  clearable v-model="TmForm.name" style="width: 150px" placeholder="产品名称" clearable size="small"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="7">
                                <el-form-item label="产品类型:" prop="fid">
                                    <el-select
                                            v-model="TmForm.fid"
                                            placeholder="请选择产品类型"
                                            size="small"
                                            clearable
                                    >
                                        <el-option
                                                v-for="item in options"
                                                :key="item.id"
                                                :label="item.fname + '---' + item.efname + '---' + item.sfname"
                                                :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <div style="margin-top: 4px">
                                    <el-button type="primary" @click="getUserList()" size="small" icon="el-icon-search"></el-button>
                                </div>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <el-table
                        height="450"
                        stripe="true"
                        :data="shopList"
                        border
                        style="width: 100%">
                    <el-table-column
                            prop="name"
                            label="产品名称"
                            width="120px"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="fname"
                            label="所属分类"
                            width="250px"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="jg"
                            label="尺寸"
                            width="250px"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="js"
                            label="介绍"
                            width="350px"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="remake"
                            label="备注"
                            width="250px"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="createdtime"
                            label="创建时间"
                            width="200px"
                    >
                    </el-table-column>

                    <el-table-column label="操作" width="200px" fixed="right">
                        <template slot-scope="scope">
                            <el-button
                                    size="mini"
                                    type="success"
                                    icon="el-icon-s-platform"
                                    @click="showBa(scope.row)"></el-button>
                            <el-button
                                    size="mini"
                                    type="primary"
                                    icon="el-icon-edit"
                                    @click="edit(scope.row)"></el-button>
                            <el-button
                                    size="mini"
                                    type="danger"
                                    icon="el-icon-delete"
                                    @click="del(scope.row)"></el-button>

                        </template>
                    </el-table-column>
                </el-table>
                <div style="margin-left: 5rem; margin-top: 1em">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="pageNum"
                            :page-sizes="[8,20,50,100]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                    >
                    </el-pagination>
                </div>

            </div>
        </el-card>

        <el-dialog
                title="修改产品"
                :visible.sync="dialogVisible"
                width="60%"
                @close="editDdialogVisibleColsed"
        >
            <div style="margin: 20px">
                <el-form
                        :model="editForm"
                        :rules="regRules"
                        ref="editForm"
                        label-width="100px"
                        class="demo-ruleForm"
                >
                    <el-form-item label="产品名称" prop="name">
                        <el-input v-model="editForm.name"></el-input>
                    </el-form-item>
                    <el-form-item label="产品封面">
                        <el-button
                                :type="editForm.savepath == '' ? 'danger' : 'success'"
                                style="width: 100%"
                                @click="showSavepath()"
                        >上传产品封面</el-button
                        >
                    </el-form-item>
                    <el-form-item label="产品相册">
                        <el-button
                                :type="editForm.pics.length == 0 ? 'danger' : 'success'"
                                @click="showPics"
                                style="width: 100%"
                        >上传产品相册</el-button
                        >
                    </el-form-item>
                    <el-form-item label="产品尺寸" prop="jg">
                        <el-input v-model="editForm.jg"></el-input>
                    </el-form-item>
                    <el-form-item label="产品介绍" prop="js">
                        <el-input
                                type="textarea"
                                v-model="editForm.js"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="备注" prop="remake">
                        <el-input v-model="editForm.remake"></el-input>
                    </el-form-item>
                    <el-form-item label="产品类型" prop="fid">
                        <el-select style="width: 100%"
                                   v-model="editForm.fid"
                                   placeholder="请选择产品类型"
                        >
                            <el-option
                                    v-for="item in options"
                                    :key="item.id"
                                    :label="item.fname + '---' + item.efname + '---' + item.sfname"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>


                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submitEditForm('editForm')">确 定</el-button>
  </span>
        </el-dialog>

        <el-dialog
                title="查看产品图集"
                :visible.sync="fjrDdialogVisible"
                width="60%"
        >
            <div style="margin: 20px">
                <div>
                    <div style="margin: 20px;font-size: 20px">
                        封面：
                    </div>
                    <div>
                        <el-card>
                            <img :src="pathImg+pic" style="width: 700px;height: 500px">
                        </el-card>
                    </div>
                    <hr>
                    <div style="margin: 20px;font-size: 20px">图集：</div>
                    <el-row :gutter="20">
                        <el-col :span="12" v-for="(item,index) in pics" :key="index">
                            <el-card>
                                <img :src="pathImg+item" style="width: 350px;height: 350px">
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
            </div>

        </el-dialog>
        <el-dialog title="产品封面" :visible.sync="showPicVisible" width="30%">
            <el-upload
                    :action="path + '/user/upload'"
                    list-type="picture-card"
                    :on-success="success"
                    :limit="1"
                    :on-exceed="savepathExceed"
                    :on-remove="savepathRemove"
            >
                <i class="el-icon-plus"></i>
            </el-upload>
        </el-dialog>
        <!--产品相册-->
        <el-dialog
                title="产品相册最多上传二十张"
                :visible.sync="showPicsVisible"
                width="50%"
        >
            <el-upload
                    :action="path + '/user/upload'"
                    list-type="picture-card"
                    :on-success="picssuccess"
                    :limit="20"
                    :on-exceed="picsExceed"
                    :on-remove="picsRemove"
                    :file-list="fileList"
            >
                <i class="el-icon-plus"></i>
            </el-upload>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showPicsVisible = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {

            return {
                regRules:
                    {
                        name: [
                            { required: true, message: '请输入产品名称', trigger: 'blur' },
                            { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
                        ],
                        jg: [
                            { required: true, message: '请输入产品尺寸', trigger: 'blur' },
                        ]
                    },
                total: 0,
                pageNum: 1,
                pageSize: 8,
                queryCommodityForm: {
                    lname: ''
                },
                TmForm:{
                    name:'',
                    fid:''
                },
                data:[],
                filterText: '',
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                shopList: [],
                options: [],
                regForm: {
                    js:'',
                    name:'',
                    remake:'',
                    jg:'',
                    savepath:'',
                    pics:[]
                },
                editForm:{
                    id:'',
                    js:'',
                    name:'',
                    remake:'',
                    jg:'',
                    savepath:'',
                    pics:[],
                    fid:''
                },
                flagPs:false,
                fileList:[],
                dialogVisible:false,
                editDdialogVisible: false,
                fjrDdialogVisible: false,
                showPicVisible: false,
                showPicsVisible: false,
                pics:[],
                pic:'',
                fileList1:[]
            }
        },
        created() {
            this.getUserList()
            this.queryDict()
        },
        watch: {
            filterText(val) {
                this.$refs.tree.filter(val);
            }
        },
        methods: {
            editDdialogVisibleColsed() {
                this.$refs.editForm.resetFields();
            },
            edit(row){

                this.dialogVisible = true
                this.editForm.id = row.id
                this.editForm.js = row.js
                this.editForm.name =row.name
                this.editForm.remake = row.remake
                this.editForm.jg = row.jg
                this.editForm.savepath = row.savepath
                this.editForm.fid = row.fid
                this.editForm.pics = row.pics
                let _this = this
                _this.fileList=[]
                for (let i = 0; i < _this.editForm.pics.length; i++) {
                    //创建对象，并将路径进行ip地址拼接
                    let obj = {
                        url: 'https://www.dzpj.shop/pet/' + _this.editForm.pics[i],
                    };
                    //放进图片列表
                    _this.fileList.push(obj);
                }
            },
            showBa(row){
                this.fjrDdialogVisible = true
                this.pics= row.pics
                this.pic = row.savepath
            },
            del(row){
                this.$confirm('确定删除该内容？, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=> {
                    this.$http.delete('/shop/delete/'+row.id).then(res => {
                        if(res.data.code === 200){
                            this.getUserList()
                            location.reload()
                        }
                    });
                    this.$message({
                        type: 'success',
                        message: '删除成功!',
                    });
                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },

            showSavepath() {
                this.showPicVisible = true;
            },
            success(response, file) {
                this.editForm.savepath = response.data;
            },
            savepathRemove() {
                this.editForm.savepath = "";
            },
            savepathExceed() {
                this.$message.error("只能上传一张图片");
            },
            showPics() {
                this.showPicsVisible = true;
            },
            picssuccess(respone, file,fileList) {
                this.fileList1 = fileList;
            },
            picsExceed() {
                this.$message.error("只能上传二十张图片");
            },
            picsRemove(file, fileList) {
                this.fileList1 = fileList;
            },
            handleSizeChange(pageSize) {
                this.pageSize = pageSize;
                this.getUserList();
            },
            handleCurrentChange(pageNum) {
                this.pageNum = pageNum;
                this.getUserList();
            },
            isgg(){
                // 先判断图片是否更改
                if (this.fileList1.length==0) {
                    //如果为0，则表示没有对图片进行编辑，则不用更改任何东西
                } else {
                    //图片进行了一些操作，包括删除、新增；
                    let arr = [];
                    // 判断是否是新上传的
                    this.fileList1.map((item,index)=>{
                        if (item.response) {
                            //是新上传的，将路径放进数组
                            arr.push(item.response.data)
                        } else {
                            //原来存在的，将路径进行截取后放进数组
                            let str = item.url.split("/");
                            arr.push(str[str.length-1])
                        }

                    })
                    console.log(arr)
                    this.editForm.pics = arr
                }
            },
            submitEditForm(formName){
                this.isgg()
                this.$refs[formName].validate(valid=>{
                    if(valid){
                        this.$http.post('/shop/updateShop',this.editForm).then(res=>{
                            if(res.data.code==200){
                                this.$message.success("修改成功")
                                this.$refs[formName].resetFields()
                                this.dialogVisible=false
                                this.getUserList()
                            }
                            else{
                                this.$message.error(res.data.data)
                            }
                        })
                    }
                    else {
                        this.$message.error('表单填写有误请检查')
                    }

                });
            },
            getUserList(){
                this.$http.get('shop/getListShop',{params:{
                        name:this.TmForm.name,
                        fid:this.TmForm.fid,
                        pageNum: this.pageNum,
                        pageSize: this.pageSize
                    }}).then(res => {
                    if(res.data.code === 200){
                        this.shopList = res.data.data.rows
                        this.pageNum = res.data.data.pageNum;
                        this.total = res.data.data.total;
                    }else{
                        this.$message.error('获取列表失败')
                    }
                })
            },

            queryDict(){
                this.$http.get('user/getSJiList',{params:{

                    }}).then(res => {
                    if(res.data.code === 200){
                        this.options = res.data.data
                    }else{
                        this.$message.error('获取列表失败')
                    }
                })
            },

        }
    }
</script>

<style scoped>

</style>